import request from '../utils/request';
import constantes from '../utils/constantes';
import auth from '../utils/auth';
var moment = require('moment');


const toogleLogin = () => {
    return {
        type: 'toogleLogin'
      }
};

const toogleIsOpen = () => {
    return {
        type: 'toogleIsOpen'
      }
};

const toogleModalAppointments = () => {
    return {
        type: 'toogleModalAppointments'
      }
};

const setIntervalId = (id) => {
    return {
        type: 'setIntervalId',
        id
    }
};

const loadAssessments = () => {
    return dispatch => {
        request(constantes("URL_API")+"/graphql?query=%7B%0Aassessments%7B%0A%20%20_id%20%0A%20%20createdAt%20%09%0A%20%20description%0A%20%20professional%7B%20%20%20%20%0A%20%20%20%20_id%20%20%20%20%0A%20%20%20%20description%0A%20%20%20%20image%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20media%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20profesional_name%0A%20%20%20%20specialties%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20name%0A%20%20%20%20%7D%20%20%20%20%0A%20%09%20%7D%0A%20%20score%0A%20%20updatedAt%0A%20%20user%7B%20%20%20%20%0A%20%20%20%20_id%0A%20%20%20%20birthdate%20%20%20%20%0A%20%20%20%20gender%0A%20%20%20%20name%20%20%20%20%0A%20%20%20%20picture_profile%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20url%0A%20%20%20%20%7D%0A%20%20%20%20username%0A%20%20%09%7D%0A%20%20%7D%0A%7D%20", { method: "GET" })
            .then(response => {
                dispatch({
                    type: 'loadAssessments',
                    assessments: response.data.assessments
                })
            })
    }
}

const loadAppointments = () => {
    return dispatch => {
        if(auth.getToken()!==null){
            const isProfessional = auth.getUserInfo().professional===null?false:true;
            const id = isProfessional?auth.getUserInfo().professional:auth.getUserInfo()._id;
            
            const uri = isProfessional?"?professional="+id:"?user="+id;

            // const now = new moment(new Date()).utc().format();
            // const min5 = moment(new Date()).add(1,'minutes').utc().format();
            // const uridate = "&datetime_gt="+now+"&datetime_lt="+min5;

            request(constantes("URL_API")+"/appointments"+uri, { method: "GET" })
            .then(response => {
                dispatch({
                    type: 'loadAppointments',
                    appointments: response
                })
            })
        }
    }
}

const loadProfessionals = () => {
    return dispatch => {
        request(constantes("URL_API")+"/professionals", { method: "GET" })
            .then(response => {
                dispatch({
                    type: 'loadProfessionals',
                    professionals: response
                })
            })
    }
}

const loadSpecialties = () => {
    return dispatch => {
        request(constantes("URL_API")+"/specialties", { method: "GET" })
            .then(response => {
                dispatch({
                    type: 'loadSpecialties',
                    specialties: response
                })
            })
    }
}

export { toogleModalAppointments, loadAppointments, setIntervalId, toogleLogin, toogleIsOpen, loadAssessments, loadProfessionals, loadSpecialties };    