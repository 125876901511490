import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import '../styles.css';
import SpecialtyCard from '../../cards/SpecialtyCard/SpecialtyCard'
import { connect } from 'react-redux';

class FeaturedSpecialties extends Component { 
  render() {
      return(
        <div className="featured-specialties">
          
          <h2 className="color-white">{this.props.titulo}</h2>
          
          { this.props.seeall ? 
          <Button>
            <Link className="see-all" to="">Ver Todos ></Link>
          </Button>
          :null }
          
          {
            this.props.specialties.slice(0,this.props.limit).map((dato) => {
              return <SpecialtyCard dato={dato} key={dato._id} />
            })
          }
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    specialties: state.specialties
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedSpecialties);
