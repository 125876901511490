import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { toogleModalAppointments } from '../../utils/actionCreators';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import auth from '../../utils/auth';
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import { loadAppointments } from '../../utils/actionCreators';
import base64 from 'base-64';

class ModalAppointments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  joinRoom = (room) => {
    this.props.toogleModalAppointments();
    this.props.history.push("/video/"+room);
  }

  confirmAppointment = (appointment) => {
    let newAppointment = {...appointment, confirmed: true}
    request(constantes("URL_API")+"/appointments/"+appointment._id, { 
        method: "PUT",
        body: newAppointment
    });  
    this.props.loadAppointments();
  }

  cancelAppointment = (appointment) => {
    request(constantes("URL_API")+"/appointments/"+appointment._id, { 
        method: "DELETE"
    });
    this.props.loadAppointments();
  }

  toggle = () => {
    this.props.toogleModalAppointments();
  }

    render() {
        const isProfessional = auth.getUserInfo()&&auth.getUserInfo().professional===null?false:true;
        return (
            <div>
                <Modal isOpen={this.props.modalAppointments} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Proximas Citas</ModalHeader>
                    <ModalBody>
                        <ul>
                            {this.props.appointments&&this.props.appointments.map((appointment,i) => {
                                // console.log({appointment})
                                return <li key={i}>
                                    {isProfessional?appointment.user.name+" "+appointment.user.surname:appointment.professional.profesional_name+"    "}
                                    <Moment format={"DD/MM/YY HH:mm"}>{appointment.datetime}</Moment>
                                    {"  "}
                                    {
                                        isProfessional?
                                            (appointment.confirmed
                                                ?
                                                <Button color="success" onClick={() => this.joinRoom(base64.encode(JSON.stringify(appointment)))}>Unirse</Button>
                                                :
                                                <div>
                                                    <Button color="primary" onClick={() => this.confirmAppointment(appointment)}>Confirmar</Button>
                                                    <Button color="danger" onClick={() => this.cancelAppointment(appointment)}>Cancelar</Button>
                                                </div>)
                                                :
                                                (appointment.confirmed
                                                    ?
                                                    <Button color="success" onClick={() => this.joinRoom(base64.encode(JSON.stringify(appointment)))}>Unirse</Button>
                                                    :
                                                    <Button outline disabled color="danger">No Aceptada</Button>)
                                    }
                                </li>
                            }
                            )}
                        </ul>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        modalAppointments: state.modalAppointments
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      toogleModalAppointments(){
        dispatch(toogleModalAppointments());
      },
      loadAppointments(){
          dispatch(loadAppointments());
      }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ModalAppointments);