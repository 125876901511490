import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './styles.css';

class Footer extends Component {
    render() {
        return(
            <div className="footer-cont">
                <div className="footer">
                    <Row>
                        <Col className="enlaces">
                            <Row>
                                <Link to="/">Sobre Mediaclive</Link>
                                <Link to="/">Ayuda</Link>
                                <Link to="/">Política de Privacidad</Link>
                            </Row>                        
                        </Col>                    
                        <Col className="copyright">
                        &#169; 2018 Todos los derechos reservados
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Footer;
