import React, {Component} from 'react';
import constantes from '../../../utils/constantes';
import '../styles.css';
import './styles.css';
import {Col, Row} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import 'moment/locale/es';
import ReadMoreReact from 'read-more-react';

class CommentCard extends Component {
    executeOnClick(isExpanded) {
        console.log(isExpanded);
    }
    
    render() {       
        return (
            <div className = "comment-card" id={"comment" + this.props.dato._id } >
                <Row className="userinfo">
                    <Col sm={this.props.proView?2:4} className="centraCircle" >
                        <div className="marco">
                            <img src= { ((this.props.dato.user.picture_profile != null)? constantes("URL_API")+this.props.dato.user.picture_profile.url : "/img/userplaceholder.jpg") } alt="" /> 
                        </div>
                    </Col>
                    <Col>
                        <p className="username">{this.props.dato.user.name}</p>
                        <div className="empresa">
                            {this.props.proView?
                                <div>
                                    <Moment format="MMMM" className="mes">{this.props.dato.createdAt}</Moment><span> de </span>  
                                    <Moment format="YYYY">{this.props.dato.createdAt}</Moment>
                                </div>:"Empresa S.A."}</div>
                    </Col>
                </Row>
                <Row className="comment">    
                    <Col sm={12}><em><FontAwesomeIcon icon={faQuoteLeft}/></em></Col>
                    <span className="description"><ReadMoreReact text={this.props.dato.description} ideal={250} max={250}/></span>
                </Row>
                {this.props.proView?<hr/>:null}
            </div> 
        )
    }
} 

export default CommentCard;
