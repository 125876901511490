import React, { Component } from 'react';
import { Router, Route } from "react-router";
import createHistory from 'history/createBrowserHistory';
import PrivateRoute from '../PrivateRoute';
import Home from "../../containers/Home";
import Header from '../Header';
import Footer from '../Footer';
import Login from '../../containers/Login';
import Welcome from '../../containers/Welcome';
import Directory from "../../containers/Directory";
import EditProfile from "../../containers/EditProfile";
import SignIn from '../../containers/SignIn';
import Professional from '../../containers/Professional';
import VideoComponent from '../VideoComponent';

import { connect } from 'react-redux';
import auth from '../../utils/auth';
import constantes from '../../utils/constantes';
import store from '../../utils/store';
import { loadAssessments, loadProfessionals, loadSpecialties, loadAppointments, setIntervalId } from '../../utils/actionCreators';

class RouterApp extends Component {

  componentDidMount(){
    const token = auth.getToken();
    if(token!==null){
      fetch(constantes("URL_API")+"/users/me", {
        method: 'GET',
        headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization': `Bearer ${token}`
        }
      }).then(data => data.json())
      .then(response => {

        let newResponse;
        if(response.professional){
          newResponse = {...response, professional: response.professional._id}
        }else{
          newResponse = {...response}
        }

        auth.setToken(response.jwt, true);
        auth.setUserInfo(newResponse, true);

        if(this.props.intervalId===undefined){
          const interval = setInterval(async () => {
              await store.dispatch(loadAppointments());
          }, constantes("SET_INTERVAL"));
          this.props.setIntervalId(interval);
        }
        store.dispatch(loadAppointments());
      })
    }
    store.dispatch(loadAssessments());
    store.dispatch(loadProfessionals());
    store.dispatch(loadSpecialties());
  }

  render() {
    const history = createHistory();
    return (
      <div>
        <Router history={history}>
        <div>
          <Header history={history}/>
          <Route exact path="/" component={ Home } />
          <Route exact path="/login" component={ Login } />
          <Route exact path="/welcome" component={ Welcome } />
          <Route exact path="/directory" component={ Directory } />
          <Route exact path="/signin" component={ SignIn } />
          <Route exact path='/professional/:id?' component={ Professional } />
          <PrivateRoute allowed={["Administrator"]} path='/profile' component={EditProfile}/>
          <PrivateRoute allowed={["Administrator"]} path='/video/:appointment' component={VideoComponent}/>
          <Footer/>
        </div>
        </Router>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
      
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loadAssessments (){
      dispatch(loadAssessments());
    },
    setIntervalId(id){
        dispatch(setIntervalId(id));
    },
    loadProfessionals(){
        dispatch(loadProfessionals());
    },
    loadSpecialties(){
        dispatch(loadSpecialties());
    },
    loadAppointments(){
        dispatch(loadAppointments());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterApp);