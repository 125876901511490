import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import './styles.css';
import auth from '../../utils/auth';
import { connect } from 'react-redux';
import store from '../../utils/store';
import ModalAppointments from '../ModalAppointments';
import { toogleModalAppointments, loadAppointments, setIntervalId, toogleLogin, toogleIsOpen } from '../../utils/actionCreators';

class Navlogin extends Component {
  constructor(props){
    super(props);
    this.state={}
  }

  toggle = () => {
    this.props.toogleModalAppointments();
  }

  logout = () => {
    clearInterval(this.props.intervalId);
    auth.clearAppStorage();
    this.props.history.push("/");
    store.dispatch(toogleLogin());
  }
  
  render() {
    return(
      <div className="nav-login">
      <ModalAppointments history={this.props.history} appointments={this.props.appointments}/>
      <Navbar className="loginBar" expand="sm">
        <NavbarToggler onClick={this.props.toggle} />
        <Collapse isOpen={this.props.isOpen} navbar>
          <Nav stacked="true" className="ml-auto" navbar>
          {this.props.hasToken&&
          <NavItem className="login">
          <Button disabled={this.props.appointments&&this.props.appointments.length===0?true:false} className="badget" onClick={this.toggle}>
            {this.props.appointments?this.props.appointments.length:0}
          </Button>
        </NavItem>}
            {!this.props.hasToken?
            <NavItem className="login">
              <Link to="/login">ACCEDER</Link>               
            </NavItem>:
            <NavItem>
              <Link to="/profile">PERFIL</Link>
            </NavItem>
          }
            {!this.props.hasToken?<NavItem>
              <Button>
                  <Link className="textoblanco" to="">EMPRESAS</Link>
              </Button>                
            </NavItem>:
            <NavItem>
            <Button onClick={this.logout} className="out">
                CERRAR SESIÓN
            </Button>                
          </NavItem>
          }
          </Nav>
        </Collapse>
      </Navbar>
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasToken: state.hasToken,
    isOpen: state.isOpen,
    intervalId: state.intervalId,
    appointments: state.appointments
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggle (){
      dispatch(toogleIsOpen());
    },
    setIntervalId(id){
      dispatch(setIntervalId(id));
    },
    loadAppointments(){
      dispatch(loadAppointments());
    },
    toogleModalAppointments(){
      dispatch(toogleModalAppointments());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navlogin);
