import React, {Component} from 'react';
import constantes from '../../../utils/constantes';
import Rating from '../../ratings/Rating';
import { Row, Col } from 'reactstrap';
import '../styles.css';
import { Link } from 'react-router-dom';

class ProfessionalCard extends Component {constructor(props){
    super(props);
    this.state = {
        sumatorio:0,
    }
    this.amount = this.amount.bind(this);
    this.sum = this.sum.bind(this);
}
    amount(item){
    return item.score;
    }
  
    sum(prev, next){
        return prev + next;
    }
    componentDidMount(){
        if (this.props.dato.assessments.length>0)
            {this.setState({
                sumatorio:this.props.dato.assessments.map(this.amount).reduce(this.sum)
            })  } else {
                this.setState({
                    sumatorio:0
                }) 
            }         
    }
    
    render() {       
        return (
            
            <div className = "professional-card" id={"professional" + this.props.dato._id } >
                
                <div className="image">                        
                    <img src= { ((this.props.dato.image != null)? constantes("URL_API")+this.props.dato.image.url : "./img/userplaceholder.jpg") } alt="" />                       
                    
                    <span>{this.props.dato.specialties[0]!==undefined?this.props.dato.specialties.map(function(specialtie){
                        return specialtie.name
                    }).join(", "):"Sin especialidad"}</span>
                </div>
                
                <div className="info">
                <Link to={"/professional/"+this.props.dato._id}><h3>{this.props.dato.user===undefined|| this.props.dato.user===null?null:(this.props.dato.user.gender==="Hombre"?"Dr. ":"Dra. ")}{this.props.dato.profesional_name}</h3></Link>
                    <p>{this.props.dato.description}</p>
                    <Rating data={this.props.dato.assessments} media={this.props.dato.assessments.length>0?this.state.sumatorio/this.props.dato.assessments.length:0}/>
                    
                </div>

                { this.props.disponibilidad===true ?
                    <Row>                                
                        <Col className="disponibilidad">
                            Online >
                        </Col>
                    </Row>:null 
                }  
                
            </div>
        
        )
    }
} 

export default ProfessionalCard;
