import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import MainSearch from '../../components/MainSearch';
import CommentsClaim from '../../components/CommentsClaim';
import ProposalClaim from '../../components/statics/ProposalClaim';
import MoreClaim from '../../components/statics/MoreClaim';
import HomeClaim from '../../components/statics/HomeClaim';
import FeaturedSpecialties from '../../components/featured/FeaturedSpecialties';
import FeaturedProfessional from '../../components/featured/FeaturedProfessional';
import './styles.css'

class Home extends Component {
    render() {
        return(
            <div className="main-content home">              
                <Row>
                    <Col>
                        <HomeClaim h2="Atención Personalizada<br/> En cualquier lugar, A cualquier hora" h3="Tu consulta online por streaming"/>
                        <MainSearch/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FeaturedSpecialties titulo="Especialidades Destacadas" limit={5} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FeaturedProfessional titulo="Especialistas Destacados" limit={5} seeall/>
                        <FeaturedProfessional titulo="Especialistas Online" limit={5} disponibilidad={true} seeall/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ProposalClaim/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MoreClaim/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CommentsClaim titulo="Mejorando la vida de cientos de personas" limit={3}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Home;
