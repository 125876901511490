import React, {Component} from 'react';
import './styles.css';
import renderHTML from 'react-render-html';

class HomeClaim extends Component {

  render() {
      return(
        <div className="home-claim">
          <h2 className="color-white">{renderHTML(this.props.h2)}</h2>
          <h3>{this.props.h3}</h3>
        </div>
      );
  }
}

export default HomeClaim;
