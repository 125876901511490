import React, { Component } from 'react';
import Video from 'twilio-video';
import auth from '../../utils/auth';
import constantes from '../../utils/constantes';
import { Button, Row, Col, Container } from 'reactstrap';
import base64 from 'base-64';

class VideoComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            appointment: JSON.parse(base64.decode(this.props.match.params.appointment)),
            localMediaAvailable: false,
            hasJoinedRoom: true,
            identity: '',
            token: ''
        }
    }

    componentDidMount(){
        const userId = auth.getUserInfo()._id;
        const tokenAuth = auth.getToken();
        const now = new Date();
        const dateAppointement = new Date(this.state.appointment.datetime);

        if(userId&&tokenAuth){
            const ms = dateAppointement.getTime() - now.getTime();
            // if(ms < constantes("TIME_LEFT_TO_JOIN") && ms > constantes("LIMIT_TO_JOIN")){
                fetch(constantes("URL_API")+"/token", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenAuth}`
                    },
                    body: JSON.stringify({
                        'identity': userId,
                        'room': this.state.appointment.room
                    })
                })
                .then(data=>data.json())
                .then(response => {
                    const { identity, token } = response;
                    this.setState({ identity, token });
                    this.joinRoom();
                });
            // }else{
            //     this.props.history.push("/");
            //     console.log("No es el momento");
            // }
        }else{
            this.props.history.push("/");
            console.log("No esta logado");
        }
    }

    attachTracks = (tracks, container) => {
        tracks.forEach(track => {
          container.appendChild(track.attach());
        });
    }

    // Attach the Participant's Tracks to the DOM.
    attachParticipantTracks = (participant, container) => {
        var tracks = Array.from(participant.tracks.values());
        this.attachTracks(tracks, container);
    }

    detachTracks = (tracks) => {
        tracks.forEach(track => {
          track.detach().forEach(detachedElement => {
            detachedElement.remove();
          });
        });
      }
    
    detachParticipantTracks = (participant) => {
      var tracks = Array.from(participant.tracks.values());
      this.detachTracks(tracks);
    }

    joinRoom = () => {
        console.log("Joining room '" + this.state.appointment.room + "'...");
        let connectOptions = {
         name: this.state.roomName
        };

        if (this.state.previewTracks) {
         connectOptions.tracks = this.state.previewTracks;
        }

        /* 
        Connect to a room by providing the token and connection    options that include the room name and tracks. We also show an alert if an error occurs while connecting to the room.    
        */  
        Video.connect(this.state.token, connectOptions).then(this.roomJoined, error => {
         alert('Could not connect to Twilio: ' + error.message);
        });
    }

    leaveRoom = () => {
        this.state.activeRoom.disconnect();
        this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
        this.props.history.push("/");
    }

    roomJoined = (room) => {
        // Called when a participant joins a room
        console.log("Joined as '" + this.state.identity + "'");
        this.setState({
            activeRoom: room,
            localMediaAvailable: true,
            hasJoinedRoom: true  // Removes ‘Join Room’ button and shows ‘Leave Room’
        });

        // Attach LocalParticipant's tracks to the DOM, if not already attached.
        var previewContainer = this.refs.localMedia;
        if (!previewContainer.querySelector('video')) {
         this.attachParticipantTracks(room.localParticipant, previewContainer);
        }
        // ... more event listeners
        if (!previewContainer.querySelector('video')) {
            this.attachParticipantTracks(room.localParticipant, previewContainer);
        }
    
        // Attach the Tracks of the room's participants.
        room.participants.forEach(participant => {
            console.log("Already in Room: '" + participant.identity + "'");
            var previewContainer = this.refs.remoteMedia;
            this.attachParticipantTracks(participant, previewContainer);
        });
    
        // Participant joining room
        room.on('participantConnected', participant => {
         console.log("Joining: '" + participant.identity + "'");
        });
    
        // Attach participant’s tracks to DOM when they add a track
        room.on('trackAdded', (track, participant) => {
            console.log(participant.identity + ' added track: ' + track.kind);
            var previewContainer = this.refs.remoteMedia;
            this.attachTracks([track], previewContainer);
        });
    
        // Detach participant’s track from DOM when they remove a track.
        room.on('trackRemoved', (track, participant) => {
            console.log(participant.identity + ' removed track: ' + track.kind);
            this.detachTracks([track]);
        });
    
        // Detach all participant’s track when they leave a room.
        room.on('participantDisconnected', participant => {
            console.log("Participant '" + participant.identity + "' left the room");
            this.detachParticipantTracks(participant);
        });
    
        // Once the local participant leaves the room, detach the Tracks
        // of all other participants, including that of the LocalParticipant.
        room.on('disconnected', () => {
            if (this.state.previewTracks) {
                this.state.previewTracks.forEach(track => {
                    track.stop();
                });
            }
            this.detachParticipantTracks(room.localParticipant);
            room.participants.forEach(this.detachParticipantTracks);
            this.setState({ 
                activeRoom: null,
                hasJoinedRoom: false,
                localMediaAvailable: false 
            });
        });  
    }

    render() {
        /* 
        Controls showing of the local track
        Only show video track after user has joined a room else show nothing 
        */
        let showLocalTrack = this.state.localMediaAvailable ? (<div ref="localMedia" />) : '';   
        /*
        Controls showing of ‘Join Room’ or ‘Leave Room’ button.  
        Hide 'Join Room' button if user has already joined a room otherwise 
        show `Leave Room` button.
        */
        let joinOrLeaveRoomButton = this.state.hasJoinedRoom ? (
            <Button color="danger" onClick={this.leaveRoom}  >Leave Room</Button>) : "";

        return (
            <Container>
                <Row>
                    <Col>
                        {showLocalTrack}        
                    </Col>
                    <Col>
                        {joinOrLeaveRoomButton}
                    </Col>
                    <Col>
                        <div ref="remoteMedia" id="remote-media" />   
                    </Col>
                </Row>
            </Container>
        );
    }

}

export default VideoComponent;