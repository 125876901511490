import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import MainSearch from '../../components/MainSearch';
import FeaturedProfessional from '../../components/featured/FeaturedProfessional';
import "./styles.css";

class Directory extends Component{
    
    render(){
        return (<div className="main-content directory">
            <Row>
                <Col>
                    <MainSearch filtros shadow/>
                    <FeaturedProfessional titulo="Profesionales para tu búsqueda" limit={ 10 } seemore />
                </Col>
            </Row>
            <Row>
                <Col className="final-content"></Col>
            </Row>
            
        </div>);
    }

}

export default Directory;