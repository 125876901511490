import React, { Component } from 'react';
import FormProfile from '../../components/FormProfile';
import {Col } from 'reactstrap';
import './styles.css';


export default class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }
      render(){
            return(
                <div className="signin">
                    <h2>Registro de forma segura</h2>
                    <FormProfile history={this.props.history} signin={true}/>
                    <Col className="final-content"/>
                </div>
            );

      }
}