import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import './styles.css';

class NavMain extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }  

  render() {

      return(
        <div>
        <Navbar className="posnavbar" expand="sm">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav stacked="true" className="ml-auto ulMain" navbar>
              <NavItem className="NavMain">
                <Link className={window.location.pathname.substring(1)==="" ?"opaco":""} to="/">INICIO</Link>
              </NavItem>
              <NavItem className="NavMain">
                <Link className={window.location.pathname.substring(1).includes("directory")||window.location.pathname.substring(1).includes("professional")?"opaco":""} to="/directory">PROFESIONALES</Link>                
              </NavItem>
              <NavItem className="NavMain">
                <Link className={window.location.href.indexOf("/how") > -1?"opaco":""} to="/#how">CÓMO FUNCIONA</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      );
  }
}

export default NavMain;
