import React, {Component} from 'react';
import '../styles.css';

class RatingParcial extends Component {

  render() {
      return(
        <div className="rating parcial">
            <span className="name">{ this.props.name }</span>
            <div className="rating-stars">
                <img src="/img/ico/rating_1.png" alt="estrella" />
                <img src="/img/ico/rating_1.png" alt="estrella" />
                <img src="/img/ico/rating_1.png" alt="estrella"  />
                <img src="/img/ico/rating_0.png" alt="estrella"  />
                <img src="/img/ico/rating_0.png" alt="estrella"  />
            </div>
        </div>
      );
  }
}

export default RatingParcial;
