import React, {Component} from 'react';
import './styles.css';
import { Row, Form, Button, Input, InputGroup} from 'reactstrap';

class MainSearch extends Component {

  render() {
      return(
        <div className={ this.props.shadow ? "main-search shadow" : "main-search"}>
          <Form>
            <InputGroup className="row"> 
                
                  <div className="col-3">
                    <Input type="select" name="especialidad" id="especialidad" placeholder="Especialidad" >
                      <option>Especialidad</option>
                      <option>2</option>
                      <option>3</option>
                    </Input>
                  </div>
                
                  <div className="col-3">
                  <Input type="select" name="disponibilidad" id="especialidad" placeholder="Disponibilidad">
                    <option>Disponibilidad</option>
                    <option>2</option>
                    <option>3</option>
                  </Input> 
                  </div>
                
                  <div className="col">
                    <Input type="text" name="texto" id="texto" placeholder="¿En qué podemos ayudar?"/>                                  
                  </div>

            </InputGroup>
          </Form>

          { this.props.filtros ?
          
          <Row className="filtros">

              <Button outline>Horario</Button>
              <Button outline>Disponibilidad</Button>
              <Button outline>Reserva Inmediata</Button>
              <Button outline>Empresa</Button>
              <Button outline>Otros Filtros</Button>
            
          </Row>:null}

        </div>
      );
  }
}

export default MainSearch;
