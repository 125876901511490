/* Fichero de constantes de la aplicación */ 


const constantes = (param) => {

    const URL_APP_DEV = "http://localhost:3000";

    // const URL_API_DEV = "http://localhost:1338";
    const URL_API_PRE = "http://52.210.215.128:1339";
    const URL_API_PRO = "https://apipsi.hub7s.com";

    const RANGO_API_CITAS = "1000"; //ms
    const SET_INTERVAL = 1000; //ms
    const TIME_LEFT_TO_JOIN = 60000; //ms
    const LIMIT_TO_JOIN = -1800000 //ms

    let data = {
        URL_APP: URL_APP_DEV,
        URL_API: URL_API_PRO,
        RANGO_API_CITAS,
        SET_INTERVAL,
        TIME_LEFT_TO_JOIN,
        LIMIT_TO_JOIN,
    }        
    return data[param];
}

export default constantes;