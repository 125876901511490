import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import '../styles.css';
import { connect } from 'react-redux';
import ProfessionalCard from '../../cards/ProfessionalCard';

class FeaturedProfessional extends Component { 
  constructor (props) {
    super(props);
    this.state={
        limit:this.props.limit
        }
    this.see=this.see.bind(this);    
    } 

  see(a) {
      if (a ==="+") {
          let b = this.state.limit+this.props.limit;
          this.setState({
              limit:b
          })
      } else if (a ==="-"){
          let b = this.state.limit - this.props.limit;
          this.setState({
              limit:b
          })
      }
  }
    
  render() {
      return(
        <div className="featured-professional">
          <h2 className="title">{this.props.titulo}</h2>
          <h3 className="subtitle">{this.props.subtitle}</h3>

        { this.props.seeall ? 
          <Button>
            <Link className="see-all" to="">Ver Todos ></Link>
          </Button>:null }

          {
              window.location.href.indexOf("/professional/") > -1? //Estamos en el perfil de un profesional?
              this.props.professionals
              .filter(professional => professional.id!==this.props.id)
              .filter(professional=>professional.specialties.some(r=>this.props.specialties.indexOf(r)))
              .slice(0,this.props.limit)
              .map((dato) => {
                  return <ProfessionalCard dato={dato} key={dato._id} disponibilidad={this.props.disponibilidad}/>
                })
                :(window.location.href.indexOf("/profile") > -1? //Estamos en el perfil de editar perfil?
                this.props.professionals
                .filter(professional=>this.props.user.favorite_professionals.map(e=>e._id).includes(professional._id))
                .slice(0,this.props.limit)
                .map((dato) => {
                    return <ProfessionalCard dato={dato} key={dato._id} disponibilidad={this.props.disponibilidad}/>
                  })
                  :this.props.professionals.slice(0,this.state.limit).map((dato) => {
                    return <ProfessionalCard dato={dato} key={dato._id} disponibilidad={this.props.disponibilidad}/>
                  }))
          }

          { this.props.seemore ?
              <span className="see-more">
                  {this.state.limit>this.props.limit?
                      <span onClick={()=>this.see("-")}>Ver menos</span>:
                      null}     
              {this.state.limit<this.props.professionals.length?<span onClick={()=>this.see("+")} >Ver más ({this.props.professionals.length} profesionales)<em>></em></span>:null}</span>
          :null }

        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    professionals: state.professionals
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedProfessional);