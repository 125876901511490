import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import Logo from '../Logo/Logo';
import NavLogin from '../NavLogin/NavLogin';
import NavMain from '../NavMain/NavMain';
import './styles.css';

class Header extends Component {
    render() {
        return(
            <div className="header">
                <Row>
                    <Col sm="3">
                        <Logo/>
                    </Col>
                    <Col>
                        <NavMain history={this.props.history}/>
                    </Col>
                    <Col sm="3">
                        <NavLogin history={this.props.history}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Header;
