import React, {Component} from 'react';
import { Row } from 'reactstrap';
import './styles.css';
import { connect } from 'react-redux';
import CommentCard from '../cards/CommentCard/CommentCard'

class CommentsClaim extends Component {   
  render() {
      return(
        <div className="comments-claim-cont">
          <div className="comments-claim">
            <h2>{this.props.titulo}</h2>
            <Row>
            {
              window.location.href.indexOf("/professional/") > -1?  
              this.props.assessments
              .filter(assessment => assessment.professional._id===this.props.id).sort(function(a, b) {
                    a = new Date(a.createdAt);
                    b = new Date(b.createdAt);
                    return a>b ? -1 : a<b ? 1 : 0;
                })
              .slice(0,this.props.limit).map((dato) => {
                  return <CommentCard dato={dato} key={dato._id} proView={this.props.proView?true:false}/>
                })
              :this.props.assessments
                .sort(function(a, b) {
                    a = new Date(a.createdAt);
                    b = new Date(b.createdAt);
                    return a>b ? -1 : a<b ? 1 : 0;
                })
                .slice(0,this.props.limit).map((dato) => {
                return <CommentCard dato={dato} key={dato._id} proView={this.props.proView?true:false}/>
              })
            }
            </Row>
          </div>
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    assessments: state.assessments
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentsClaim);