import React, {Component} from 'react';
import './styles.css';
import { Row, Col, Button} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faClock, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'

class MoreClaim extends Component {

  render() {
      return(
        <div className="more-claim">          
          <Row>          
            <Col className="claim">
                <h2 >En tu móvil o desde<br/> donde prefieras</h2>
                <span>
                Nuestra plataforma de streaming te permite acceder a las consultas online desde cualquier dispositivo de forma segura y privada. También podrás acceder desde tu espacio de trabajo, casa, viajes de negocios, vacaciones...
                </span>
                <div>
                <ul>
                  <li><Button><FontAwesomeIcon icon={faWindowMaximize} />Desde donde quieras</Button></li>
                  <li><Button><FontAwesomeIcon icon={faClock} />A cualquier hora</Button></li>
                  <li><Button><FontAwesomeIcon icon={faComments} />Consulta en tiempo real</Button></li>
                </ul>
                </div>
            </Col>  
            <Col className="img">
              <img src={"/img/responsive.png"} alt="responsive"/>
            </Col>          
          </Row>
        </div>
      );
  }
}

export default MoreClaim;
