import React, { Component } from 'react';
import { Form, Input, Button, Label, Row, Col, Container, FormFeedback, FormGroup, CustomInput } from 'reactstrap';
import DatePicker from "react-datepicker";
import constantes from '../../utils/constantes';
import "react-datepicker/dist/react-datepicker.css";
import auth from '../../utils/auth';
import './styles.css';


export default class FormProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signin: this.props.signin,
            user: !this.props.signin?this.props.user:{
                birthdate: new Date(),
                username: '',
                name: '',
                surname: '',
                email: '',
                phone: '',
                nif: '',
                address: '',
                city: '',
                cp: '',
                gender: 'Hombre'
            },
            validateForm:{
                validateEmail: false,
                validateTlf: false,
                validateDni: false,
                validateCp: false,
                errorEmail: '',
                errorTlf: '',
                errorDni: '',
                errorCp: ''
            }
        };
      }

    onFormSubmit = async (e) => {
        e.preventDefault();
        let user = {
            username: this.usernameInput.value,
            nif: this.nifInput.value,
            name: this.nameInput.value,
            surname: this.surnameInput.value,
            phone: this.phoneInput.value,
            address: this.addressInput.value,
            city: this.cityInput.value,
            cp: this.cpInput.value,
            gender: this.genderInput.value,
            birthdate: this.state.user.birthdate
        }
        if(this.state.signin){
            user = {
                ...user, 
                email: this.emailInput.value,
                privacity: this.privacityInput.checked,
                news: this.newsInput.checked
            }
        }else{
            if(this.passwordInput.value!==""){
                user = {
                    ...user, 
                    password: this.passwordInput.value
                }
            }
            if(this.state.user.email !== this.emailInput.value){
                user = {
                    ...user, 
                    password: this.passwordInput.value,
                    email: this.emailInput.value,
                }
            }
        }
        const uri = this.props.signin?"/auth/local/register":"/users/"+this.state.user._id;
        const method = this.props.signin?"POST":"PUT";
        const token = auth.getToken();
        // console.log({user,uri,method})
        fetch(constantes("URL_API")+uri, {
			method,
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                'Authorization': token&&`Bearer ${token}`
			}
        }).then(data => data.json())
        .then(response => {
            console.log(response);
            if(response.statusCode === 400)throw new Error(response.message);
            auth.setToken(response.jwt, true);
            auth.setUserInfo(response.user, true);
            this.props.history.push('/');
        }).catch(err=>console.log("error:"+err));
    }

    validateForm = () => {
        let email = this.emailInput.value;
        let regEmail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        let tlf = this.phoneInput.value;
        let regTlf = /^[9|6|7][0-9]{8}$/;
        let dni = this.nifInput.value;
        let regDni = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
        let cp = this.cpInput.value;
        let regCp = /0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}/;

        let validateForm = this.state.validateForm;
        validateForm.validateEmail = false;
        validateForm.validateTlf = false;
        validateForm.validateDni = false;
        validateForm.validateCp = false;

        this.setState({
            validateForm
        })

        

       if(!regEmail.test(email)){
           validateForm.validateEmail = true;
           validateForm.errorEmail = "Email no válido";
        }
        

        if(!regTlf.test(tlf)){
           validateForm.validateTlf = true;
           validateForm.errorTlf = "Teléfono no válido";
        }

        if(!regDni.test(dni)){
            validateForm.validateDni = true;
            validateForm.errorDni = "Formato dni incorrecto";

        }

        if(!regCp.test(cp)){
            validateForm.validateCp = true;
            validateForm.errorCp = "Codigo postal incorrecto";
        }
            
        this.setState({
            validateForm
    })
        
    }

      handleChange = (date) => {
        this.setState({
            user: {...this.state.user, birthdate:date}
        });
      }

      render(){
        const { signin } = this.state;
            return(
                <Form className="form-profile" onSubmit={this.onFormSubmit}>
                    <Container className="formProfile-container shadow">
                    
                    <div className="formProfile-row">
                        <Row>
                            <Col xs="6">
                                <Label for="User">Usuario</Label>
                                <Input innerRef={(username) => this.usernameInput = username} type="text" name="userName" id="userName" placeholder="Usuario" defaultValue={this.state.user&&this.state.user.username}/>
                            </Col>
                            <Col xs="6">
                                <Label for="Password">{!signin?"Nueva Contraseña":"Contraseña"}</Label>
                                <Input innerRef={(password) => this.passwordInput = password} type="password" name="password" id="password" placeholder={!signin?"••••••••••":"Contraseña"}/>
                            </Col>
                        </Row>
                    </div>
                    <div className="formProfile-row">
                        <Row>
                            <Col xs="6">
                                <Label for="Nombre">Nombre</Label>
                                <Input innerRef={(name) => this.nameInput = name} type="text" name="Nombre" id="Nombre" placeholder="Nombre" defaultValue={this.state.user&&this.state.user.name} />
                            </Col>
                            <Col xs="6">
                                <Label for="Apellidos">Apellidos</Label>
                                <Input innerRef={(surname) => this.surnameInput = surname} type="text" name="Apellidos" id="Apellidos" placeholder="Apellidos" defaultValue={this.state.user&&this.state.user.surname}/>
                            </Col>
                        </Row>
                    </div>
                    <div className="formProfile-row">
                        <Row>
                            <Col xs="6">
                                <Label for="Email">Email</Label>
                                <Input onBlur={this.validateForm} invalid={this.state.validateForm.validateEmail} innerRef={(email) => this.emailInput = email} type="text" name="Email" id="Email" placeholder="Email" defaultValue={this.state.user&&this.state.user.email} />
                                <FormFeedback>{this.state.validateForm.errorEmail}</FormFeedback>
                            </Col>
                            <Col xs="6">
                                <Label for="Teléfono">Teléfono</Label>
                                <Input  onBlur={this.validateForm} invalid={this.state.validateForm.validateTlf} innerRef={(phone) => this.phoneInput = phone} type="text" name="Teléfono" id="Teléfono" placeholder="Teléfono" defaultValue={this.state.user&&this.state.user.phone} />
                                <FormFeedback>{this.state.validateForm.errorTlf}</FormFeedback>
                            </Col>
                        </Row>
                    </div>
                    <div className="formProfile-row">
                        <Row>
                            <Col xs="6">
                                <Label for="Dni">Dni</Label>
                                <Input onBlur={this.validateForm} invalid={this.state.validateForm.validateDni} innerRef={(nif) => this.nifInput = nif} type="text" name="Dni" id="Dni" placeholder="Dni" defaultValue={this.state.user&&this.state.user.nif} />
                                <FormFeedback>{this.state.validateForm.errorDni}</FormFeedback>
                            </Col>
                        </Row>
                    </div>
                    <div className="formProfile-row">
                        <Row>
                            <Col xs="12">
                                <Label for="Dirección">Dirección</Label>
                                <Input innerRef={(address) => this.addressInput = address} type="text" name="Dirección" id="Dirección" placeholder="Dirección"  defaultValue={this.state.user&&this.state.user.address}/>
                            </Col>
                        </Row>
                    </div>
                    <div className="formProfile-row">
                        <Row>
                            <Col xs="6">
                                <Label for="Ciudad">Ciudad</Label>
                                <Input innerRef={(city) => this.cityInput = city} type="text" name="Ciudad" id="Ciudad" placeholder="Ciudad"  defaultValue={this.state.user&&this.state.user.city}/>
                            </Col>
                            <Col xs="6"> 
                                <Label for="CP">CP</Label>
                                <Input onBlur={this.validateForm} invalid={this.state.validateForm.validateCp} innerRef={(cp) => this.cpInput = cp} type="text" name="CP" id="CP" placeholder="CP"  defaultValue={this.state.user&&this.state.user.cp}/>
                                <FormFeedback>{this.state.validateForm.errorCp}</FormFeedback>
                            </Col>
                        </Row>
                    </div>
                    <div className="formProfile-row">
                        <Row>
                            <Col xs="6">
                            <Label for="gender">Sexo</Label>
                            <Input innerRef={(gender) => this.genderInput = gender} type="select" name="gender" id="gender">
                                <option value="Hombre" defaultValue={this.state.user&&this.state.user.gender==="Hombre"?true:false}>Hombre</option>
                                <option value="Mujer" defaultValue={this.state.user&&this.state.user.gender==="Mujer"?true:false}>Mujer</option>
                            </Input>
                            </Col>
                            <Col xs="6">
                                <Label for="Fecha Nacimiento">Fecha Nacimiento</Label><br></br>
                                <DatePicker id="fechaNac"
                                    // selected={new Date(this.state.user.birthdate)}
                                    onChange={this.handleChange}
                                    placeholderText="Fecha Nacimiento" 
                                    dateFormat="dd/MM/yyyy"
                                />
                            </Col>
                        </Row>
                    </div>
                    {(signin) &&
                    <div className="formProfile-check">
                        <FormGroup>
                            <Label check/>
                            <div>
                                <CustomInput innerRef={(privacity) => this.privacityInput = privacity} type="checkbox" id="aceptoPolitica" label="Acepto la política de privacidad y condiciones de uso del servicio de DRoom" />
                                <CustomInput innerRef={(news) => this.newsInput = news} type="checkbox" id="aceptoPubli" label="Acepto recibir comunicaciones comerciales sobre productos y servicios de la plataforma" />
                            </div>
                        </FormGroup>
                    </div>
                    }
                    <div className="formProfile-row">
                    {(signin) ?
                        <Row>
                            <Col >
                                <Button className="formProfile-Button" color="primary">Registrarse  ></Button>
                            </Col>
                        </Row>

                        :

                        <Row>
                            <Col >
                                <Button  className="formProfile-Button" color="primary">Guardar  ></Button>
                            </Col>
                        </Row>
                        
                    }
                        </div>
                    </Container>
                </Form>
                

            );

      }
}