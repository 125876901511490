import React, { Component } from 'react';
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import {Col } from 'reactstrap';
import NavUser from '../../components/NavUser';
import './styles.css';

export default class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }
      componentDidMount = async () => {
            request(constantes("URL_API") + "/users/me", { method: "GET" })
            .then( response => {
                this.setState({
                    user: response
                });
            });
        }
      render(){
            return(
                <div className="editContainer">
                    {this.state.user&&<h2>Bienvenido {this.state.user.name+" "+this.state.user.surname}</h2>}
                    {this.state.user&&<Col className="edit-form"><NavUser history={this.props.history} signin={false} user={this.state.user}/></Col>}
                    {this.state.user&&<Col className="final-content"></Col>}
                </div>
            );

      }
}