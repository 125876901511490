import React, { Component } from 'react';
import { Form, FormGroup, Input, Button, Label, Row, Col } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import auth from '../../utils/auth';
import constantes from '../../utils/constantes';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import store from '../../utils/store';
import { loadAppointments, setIntervalId, toogleLogin } from '../../utils/actionCreators';

import './styles.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkReg: Boolean
        };
      }

    onFormSubmit = async (e) => {
        e.preventDefault();
        const body = {
            identifier: this.userInput.value,
            password: this.passwordInput.value,
            // rememberMe: this.rememberMeInput.checked
        }
        // console.log(body)
        fetch(constantes("URL_API")+"/auth/local", {
			method: 'POST',
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
			}
        }).then(data => data.json())
        .then(response => {
            if(response.statusCode === 400)throw new Error("Usuario o contraseña incorrecta");
            auth.setToken(response.jwt, body.rememberMe);
            auth.setUserInfo(response.user, body.rememberMe);
            this.props.login();
            store.dispatch(loadAppointments());
            const interval = setInterval(async () => {
                await store.dispatch(loadAppointments());
            }, constantes("SET_INTERVAL"));
            this.props.setIntervalId(interval);
            this.props.history.push('/');
        }).catch(err=>console.log(err));
    }

      async componentDidMount(){
        this.setState({
            checkReg: true
        })
      }

      render(){
            return(
                <div>
                    <h2 className="color-white">Acceder de forma segura</h2>
                    <div className="main-content form-template shadow" >    
                        <Row>
                            <Col>
                            
                                <Form onSubmit={this.onFormSubmit}>
                                    <div className = "form-fields">
                                    
                                    
                                        <Label for="User">Usuario</Label>
                                        <Input innerRef={(user) => this.userInput = user} type="text" name="User" id="User" placeholder="Usuario"/>
                                
                                        <Label for="Contraseña">Contraseña</Label>
                                        <Input innerRef={(password) => this.passwordInput = password} type="password" name="Contraseña" id="Contraseña" placeholder="Contraseña" />
                            
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" innerRef={(rememberMe) => this.rememberMeInput = rememberMe}/>{' '}
                                                Recordar mi usuario.
                                            </Label>
                                        </FormGroup>
                                    
                                        <Button  className="login-button" color="primary" onClick={this.submit}>Acceder ></Button>
                                    
                                        <Link className="to-signin" to="/signin">Registro de nuevo usuario</Link>
                                        
                                    </div>
                                </Form>
                                
                            </Col>
                        </Row>
                    
                    </div>
                </div>
            );

      }
}

const mapStateToProps = state => {
    return {
      hasToken: state.hasToken,
      isOpen: state.isOpen,
      appointments: state.appointments
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      login (){
        dispatch(toogleLogin());
      },
      setIntervalId(id){
          dispatch(setIntervalId(id));
      },
      loadAppointments(){
          dispatch(loadAppointments());
      }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Login);