import React, {Component} from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import './styles.css';

class Welcome extends Component {
    render() {
        return(

            <div>
                <h2 className="color-white">Ya eres parte de la plataforma</h2>
                <div className="main-content form-template shadow" > 
                
                        <div className = "form-fields">

                            <h3>¡Enhorabuena! Ya eres parte de la plataforma</h3>

                            <div className="txt">
                                <p>En breves recibirás un email con la contraseña para poder acceder a la plataforma. Con esa contraseña podrás acceder a tu
                                área de usuario donde tendrás opción de cambiarla y configurar tus preferencias de pago.</p>
                            </div>
                            
                            <Button  className="login-button" color="primary" onClick={this.submit}>Acceder ></Button>
                            
                            <Link className="to-signin" to="/signin">No he recibido el email</Link>
                            
                        </div>
                
                </div>
            </div>
        );
    }
}

export default Welcome;
