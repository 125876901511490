import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { Row, Col} from 'reactstrap';
import ScrollableAnchor from 'react-scrollable-anchor'

class ProposalClaim extends Component {

  render() {
      return(
        <div className="proposal-claim">
          <a id="how"><h2>Nuestra propuesta</h2></a>
          <Row>
            <Col className="tarjeta" >
                <div>
                  <img src={"/img/encuentraespecialista.png"} alt="encuentraespecialistas"/>
                </div>
                <h3>Encuentra tu especialista</h3>
                <div className="desc">
                Encuentra el especialista en el campo y necesidades que tengas entre nuestro ámplio catálogo de profesionales.
                </div>
                <div>
                <Link to="">Saber más ></Link>
                </div>
            </Col>
            <Col className="tarjeta">
                <div>
                  <img src={"/img/tusterminos.png"} alt="tusterminos"/>
                </div>
                <h3>Con tus términos</h3>
                <div className="desc">
                Olvídate de desplazarte a ninguna consulta. Desde nuestra consulta virtual te podemos atender desde tu casa.
                </div>
                <div>
                 <Link to="">Saber más ></Link>                  
                </div>
            </Col>
            <Col className="tarjeta">
                <div>
                  <img src={"/img/certificados.png"} alt="certificados"/>
                </div>
                <h3>Profesionales certificados</h3>
                <div className="desc">
                Todos nuestros especialistas son certificados por nuestro grupo de seleccionadores y referidos.
                </div>
                <div>
                <Link to="">Saber más ></Link>
                </div>
            </Col>
          </Row>
        </div>
      );
  }
}

export default ProposalClaim;
