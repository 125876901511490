import React, {Component} from 'react';
import constantes from '../../../utils/constantes';
import '../styles.css';

class SpecialtyCard extends Component {
    
    render() {       
        return (
            
            <div className = "specialty-card" id={"specialty" + this.props.dato._id } >

                <div className="image">                        
                    <img src= { ((this.props.dato.image != null)? constantes("URL_API")+this.props.dato.image.url : "./img/userplaceholder.jpg") } alt="" />                       
                </div>

                <div className="info">
                    <h3 className="card-title">{this.props.dato.name}</h3>
                    <span className="reviews-cont">{this.props.dato.professionals.length} {this.props.dato.professionals.length!==1?"Profesionales":"Profesional"}</span>
                </div>

            </div>
        
        )
    }
} 

export default SpecialtyCard;
