import React, {Component} from 'react';
import '../styles.css';

class Rating extends Component {

    constructor(props){
        super(props);
        this.state = {
        }
        this.printRating = this.printRating.bind(this);
        this.estrellas = this.estrellas.bind(this);
        this.title = this.title.bind(this);
    }
    title(){
        return <span className="reviews-cont">{ this.props.data ? this.props.data.length : "0" } {this.props.data ? (this.props.data.length!==1?" Reviews":" Review") : " Reviews"}</span>
    }
    estrellas(){
        return <div className="rating-stars">
                    <img src={this.props.media>0?"/img/ico/rating_1.png":"/img/ico/rating_0.png"} alt="estrella" />
                    <img src={this.props.media>1?"/img/ico/rating_1.png":"/img/ico/rating_0.png"} alt="estrella" />
                    <img src={this.props.media>2?"/img/ico/rating_1.png":"/img/ico/rating_0.png"} alt="estrella"  />
                    <img src={this.props.media>3?"/img/ico/rating_1.png":"/img/ico/rating_0.png"} alt="estrella"  />
                    <img src={this.props.media>4?"/img/ico/rating_1.png":"/img/ico/rating_0.png"} alt="estrella"  />
                </div>
    }
    
    printRating(){
        return this.props.profile ? 

            <div className="rating profile">
                { this.estrellas() }
                { this.title() }
            </div>
            :
            <div className="rating">
                { this.title() }
                { this.estrellas() }
            </div>
            
    }

    render() {
        return this.printRating();
    }
}

export default Rating;
