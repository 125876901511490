import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faCreditCard, faHistory, faHeart } from '@fortawesome/free-solid-svg-icons'
import FeaturedProfessional from '../../components/featured/FeaturedProfessional';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FormProfile from '../FormProfile';
import "react-tabs/style/react-tabs.css";
import './styles.css';


export default class NavUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signin: this.props.signin,
            checkReg: Boolean,
            user:{favorite_professionals:[]}
        };
      
      
      }

      componentDidMount(){
        this.setState({
            checkReg: true
        })

      }

      render(){
        const { checkReg, signin } = this.state;
            return(
                    <div className="NavUser-container">
                        {(checkReg) &&
                        <Tabs>
                            {(!signin) &&
                        <TabList className="navUser-tabList">
                          <Tab><FontAwesomeIcon className="navUser-Icons" icon={faUserCircle}/>Información<br/> Personal</Tab>
                          <Tab><FontAwesomeIcon className="navUser-Icons"  icon={faCreditCard}/>Información<br/> de Pago</Tab>
                          <Tab><FontAwesomeIcon className="navUser-Icons"  icon={faHistory}/>Historial<br/> de Consultas</Tab>
                          <Tab><FontAwesomeIcon className="navUser-Icons"  icon={faHeart}/>Profesionales<br/> Favoritos</Tab>
                        </TabList>
                            }
                        <TabPanel>
                          <FormProfile user={this.props.user}/>
                        </TabPanel>
                        <TabPanel>
                          <h2>Any content 2</h2>
                        </TabPanel>
                        <TabPanel>
                          <h2>Any content 3</h2>
                        </TabPanel>
                        <TabPanel>
                          <FeaturedProfessional                             
                              limit={this.props.user.favorite_professionals.length} id={this.state.id} user={this.props.user}/>
                        </TabPanel>
                      </Tabs>
                        }
                    </div>
                        
                 
               
                

            );

      }
}