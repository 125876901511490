import React, {Component} from 'react';
import { Row, Col, Button, Input, Modal, ModalBody} from 'reactstrap';
import request from '../../utils/request';
import constantes from '../../utils/constantes';
import MainSearch from '../../components/MainSearch';
import CommentsClaim from '../../components/CommentsClaim';
import FeaturedProfessional from '../../components/featured/FeaturedProfessional';
import Rating from '../../components/ratings/Rating';
import RatingParcial from '../../components/ratings/RatingParcial';
import auth from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BigCalendar from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { faCalendarAlt, faHeart  } from '@fortawesome/free-solid-svg-icons';
import { Player, ControlBar } from 'video-react';
import "video-react/dist/video-react.css";
import moment from 'moment'
import Moment from 'react-moment';
import 'moment/locale/es';
import renderHTML from 'react-render-html';
import './styles.css';
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from "react-datetime-picker";


class Professional extends Component {
    constructor (props) {
        super(props);
        this.state={
            count: 0,
            fechas:{},
            events:[],
            sumatorio: 0,
            professionalAppointments:[],
            professionalSchedules:[],
            modalMod:false,
            modalHorarios: false,
            id:this.props.match.params.id,
            professional:{
                specialties:[],
                media:{url:null},
                image:{url:null},
                user:{gender:"null"},
                arrayFavoritos:[],
                assessments:{},
                date: new Date(),
                notes: "",
                showAppointmentForm: false
            }, 
            jwt: auth.getToken(),
            moments: [],
            moment_selected: {},
            form: {
                starthour: '',
                startmin: 0,
                endhour: '',
                endmin: 0,
                week_day: "Lunes",
                minduration: 30,
                posible_min_select: ["00", "30"]
            },
            created_moment: []           
        }
        this.amount = this.amount.bind(this);
        this.sum = this.sum.bind(this);
        this.toggleMod = this.toggleMod.bind(this);
        this.cambiaModal = this.cambiaModal.bind(this);
        this.gestionaEventos = this.gestionaEventos.bind(this);
    }
    async gestionaEventos() {
        this.setState({events:[]});
        if(auth.getToken() !== null){
          await request(constantes("URL_API") + "/appointments/", { method: "GET" })
          .then( datos => this.setState({
              professionalAppointments: datos.filter(a => a.professional._id===this.props.match.params.id)
          }, this.calculaFechas));          
          await request(constantes("URL_API") + "/newschedules/", { method: "GET" })
            .then( datos => this.setState({
                professionalSchedules: datos.filter(a => a.professional._id===this.props.match.params.id)
            }, this.rellenaHorarios));
            this.calculaCalendario()
        }
        
      }
    toggleMod() {
        this.setState({
          modalMod: !this.state.modalMod
        });
      }
    toggleHorarios = () => {
        this.setState({
          modalHorarios: !this.state.modalHorarios
        });
      }
      handleSelect = ({ start, end }) => {
        let trueEnd = new Date(moment(end).subtract(1, 'second'));
        if(
            (this.state.events.some(e => e.start <= start && e.end >= trueEnd)) ||
            (this.state.events.some(e => e.start <= start && e.end <= trueEnd && e.end >= start))||
            (this.state.events.some(e => e.start >= start && e.end >= trueEnd && e.start <= trueEnd))
          ) {
            console.log("No deben pisarse los eventos")
                        
          } else {  
              if (this.state.events.length===this.state.totalEventos)
                {const title = 'Fecha deseada'        
                this.setState({
                    events: [
                    ...this.state.events,
                    {
                        start,
                        end: trueEnd,
                        title,
                    },
                    ],
                    date: start
                })} else {
                    const title = 'Fecha deseada'
                    let array = this.state.events;
                    array.splice(-1,1);
                    this.setState({
                        events: array
                    })
                    this.setState({
                        events: [
                        ...this.state.events,
                        {
                            start,
                            end: trueEnd,
                            title,
                        },
                        ],
                        date: start
                    })
                }
          }
            
      }
    amount(item){
        return item.score;
        }
      
        sum(prev, next){
            return prev + next;
        }
    Capitalize = (str) =>{
            return str.charAt(0).toUpperCase() + str.slice(1);
            }
    guardarCita = () => {
        const apoointment = {
            datetime: this.state.date,
            notes: this.state.notes,
            user: auth.getUserInfo()._id,
            professional: this.state.professional._id,
            room: this.state.professional._id+"_"+auth.getUserInfo()._id+"_"+this.state.date
        }
        this.gestionaEventos();
        request(constantes('URL_API')+"/appointments", {
            method: 'POST',
            body: apoointment
        })
        .then((response) => {
            this.toggleMod();
        })
        .catch((e)=>console.log(e));

    }
    deshazFecha =()=>{        
        if (this.state.events.length>this.state.totalEventos){
            let array = this.state.events;
            array.splice(-1,1);
            this.setState({
                events: array,
                date:""
            })
        }
    }
    rellenaHorarios =()=>{
        let semana = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
        semana.map((b)=>{
            if (this.state.professionalSchedules.some(e => e.week_day === b)){
              //chequear por franja horaria               
                if (this.state.professionalSchedules.some(e => e.moment.moment === "Mañana"&&e.week_day===b))
                  {
                  this.state.fechas[b].map((fecha)=>{                
                    let a = this.state.events;
                    let proScheS = this.state.professionalSchedules.filter(element => element.week_day===b&&element.moment.moment==="Mañana")[0].time_start.substr(0,1)
                    let proScheE = this.state.professionalSchedules.filter(element => element.week_day===b&&element.moment.moment==="Mañana")[0].time_end.substr(0,1)
                     a.push({
                        'title': "Fuera de horario",
                        'start': new Date(moment(fecha).set({hour:0,minute:0,second:0})),
                        'end': new Date (moment(fecha).set({hour: proScheS,minute:0,second:0}).subtract(1, "seconds")),
                        'bgColor': "#666666",
                        'allDay': false              
                        },{
                          'title': "Fuera de horario",
                          'start': new Date(moment(fecha).set({hour:proScheE,minute:0,second:0})),
                          'end': new Date (moment(fecha).set({hour:7,minute:59,second:59})),
                          'bgColor': "#666666",
                          'allDay': false              
                          });
                    this.setState({
                        events: a
                    })
                    }
                  )
                  } 
                  else 
                  {this.state.fechas[b].map((fecha)=>{                
                    let a = this.state.events;
                    a.push({
                        'title': "Fuera de horario",
                        'start': new Date(moment(fecha).set({hour:0,minute:0,second:0})),
                        'end': new Date (moment(fecha).set({hour:7,minute:59,second:59})),
                        'bgColor': "#666666",
                        'allDay': false              
                    });
                    this.setState({
                        events: a
                    })
                    }
                  )
                  }
                if (this.state.professionalSchedules.some(e => e.moment.moment === "Tarde"&&e.week_day===b))
                  {this.state.fechas[b].map((fecha)=>{                
                    let a = this.state.events;
                    let proScheS = this.state.professionalSchedules.filter(element => element.week_day===b&&element.moment.moment==="Tarde")[0].time_start.substr(0,2)
                    let proScheE = this.state.professionalSchedules.filter(element => element.week_day===b&&element.moment.moment==="Tarde")[0].time_end.substr(0,2)
                     a.push({
                        'title': "Fuera de horario",
                        'start': new Date(moment(fecha).set({hour:8,minute:0,second:0})),
                        'end': new Date (moment(fecha).set({hour: proScheS,minute:0,second:0}).subtract(1, "seconds")),
                        'bgColor': "#666666",
                        'allDay': false              
                        },{
                          'title': "Fuera de horario",
                          'start': new Date(moment(fecha).set({hour:proScheE,minute:0,second:0})),
                          'end': new Date (moment(fecha).set({hour:15,minute:59,second:59})),
                          'bgColor': "#666666",
                          'allDay': false              
                          });
                    this.setState({
                        events: a
                    })
                    }
                  )
                } 
                  else 
                  {this.state.fechas[b].map((fecha)=>{                
                    let a = this.state.events;
                    a.push({
                        'title': "Fuera de horario",
                        'start': new Date(moment(fecha).set({hour:8,minute:0,second:0})),
                        'end': new Date (moment(fecha).set({hour:15,minute:59,second:59})),
                        'bgColor': "#666666",
                        'allDay': false              
                    });
                    this.setState({
                        events: a
                    })
                    }
                  )
                  }
                if (this.state.professionalSchedules.some(e => e.moment.moment === "Noche"&&e.week_day===b))
                  {
                  this.state.fechas[b].map((fecha)=>{                
                    let a = this.state.events;
                    let proScheS = this.state.professionalSchedules.filter(element => element.week_day===b&&element.moment.moment==="Noche")[0].time_start.substr(0,2)
                    let proScheE = this.state.professionalSchedules.filter(element => element.week_day===b&&element.moment.moment==="Noche")[0].time_end.substr(0,2)
                     a.push({
                        'title': "Fuera de horario",
                        'start': new Date(moment(fecha).set({hour:16,minute:0,second:0})),
                        'end': new Date (moment(fecha).set({hour: proScheS,minute:0,second:0}).subtract(1, "seconds")),
                        'bgColor': "#666666",
                        'allDay': false              
                        },{
                          'title': "Fuera de horario",
                          'start': new Date(moment(fecha).set({hour:proScheE,minute:0,second:0})),
                          'end': new Date (moment(fecha).set({hour:23,minute:59,second:59})),
                          'bgColor': "#666666",
                          'allDay': false              
                          });
                    this.setState({
                        events: a
                    })
                    }
                  )
                } 
                  else 
                  {this.state.fechas[b].map((fecha)=>{                
                    let a = this.state.events;
                    a.push({
                        'title': "Fuera de horario",
                        'start': new Date(moment(fecha).set({hour:16,minute:0,second:0})),
                        'end': new Date (moment(fecha).set({hour:23,minute:59,second:59})),
                        'bgColor': "#666666",
                        'allDay': false              
                    });
                    this.setState({
                        events: a
                    })
                    }
                  )
                  }
                
            } else {
              //reservar todos {NombreDeDia}
              this.state.fechas[b].map((fecha)=>{                
                let a = this.state.events;
                a.push({
                    'title': "Fuera de horario",
                    'start': new Date(moment(fecha).set({hour:0,minute:0,second:0})),
                    'end': new Date (moment(fecha).set({hour:23,minute:59,second:59})),
                    'bgColor': "#666666",
                    'allDay': false              
                });
                this.setState({
                    events: a
                })
                }
              )
            }
      
          }
        )        
      }
    calculaCalendario = () => {
        this.state.professionalAppointments.map((element)=>            
        { 
        let a = this.state.events;
        a.push({
            'title': "No disponible",
            'start': new Date(moment(element.datetime)),
            'end': new Date (moment(element.datetime).add(45, "minutes")),
            'bgColor': "#a51f04",
            'allDay': false
            
        });
        this.setState({
            events: a
        }, this.setState({totalEventos: this.state.events.length}))
        }
        )
    }
    calculaFechas = () =>{
        let semana = [0,1,2,3,4,5,6];
        semana.map((element)=>{
        let array = this.state.fechas;
        let dayN = this.Capitalize(moment().add(element, "days").format("dddd"));
        array[dayN]=[
                moment().add(element, "days").add(0, "week"),
                moment().add(element, "days").add(1, "week"),
                moment().add(element, "days").add(2, "week"),
                moment().add(element, "days").add(3, "week"),
                moment().add(element, "days").add(4, "week"),
                moment().add(element, "days").add(5, "week"),
                moment().add(element, "days").add(6, "week"),
                moment().add(element, "days").add(7, "week"),
                moment().add(element, "days").add(8, "week"),
                moment().add(element, "days").add(9, "week"),
                moment().add(element, "days").add(10, "week"),
                moment().add(element, "days").add(11, "week"),
                moment().add(element, "days").add(12, "week"),
                moment().add(element, "days").add(13, "week"),
                moment().add(element, "days").add(14, "week")
            ];  
        this.setState({fechas:array});           
        })
        }

    toggle = () => {
        this.setState({
            showAppointmentForm: !this.state.showAppointmentForm,
            professionalSchedules: [],
            professionalAppointments: [],
            events:[]
        }, this.gestionaEventos);
    }
    

    handleChange = (date) => {
        this.setState({
            date
        });
    }
    cambiaModal(){
        this.setState({
            showAppointmentForm: !this.state.showAppointmentForm,
            modalMod: !this.state.modalMod
        })
    }

    handleChangeNotes = (notes) => {
        this.setState({
            notes: notes.target.value
        });
    }
    gestionaFav = () => {
        if (this.state.arrayFavoritos.some(item=> item._id === this.state.id)===false) {
            //añadirlo
            let arrayMutable = [...this.state.arrayFavoritos];
            let nuevoFav = {_id:this.state.id};
            arrayMutable.push(nuevoFav);
            this.setState({
                arrayFavoritos:arrayMutable
            })
            let body = {favorite_professionals: arrayMutable}
            request(constantes("URL_API") + "/users/"+auth.getUserInfo()._id, {
                method: "PUT",
                body: body,
            })
        } else {
            //restarlo
            let id = this.state.id
            let arrayMutable = this.state.arrayFavoritos.filter(function(obj){return obj._id !==id});
            this.setState({
                arrayFavoritos:arrayMutable
            })
            let body = {favorite_professionals: arrayMutable}
            request(constantes("URL_API") + "/users/"+auth.getUserInfo()._id, {
                method: "PUT",
                body: body,
            })
        }
    };
    componentDidMount(){         
        if (this.props.match.params.id!==undefined){
            let consulta = "/graphql?query=%7B%0A%20professional(id%3A%22"+this.props.match.params.id+"%22)%7B%0A%20%20%20%20_id%0A%20%20%09profesional_name%0A%20%20%20%20description%0A%20%20%09createdAt%0A%09%09biography%0A%09%20%20media%7B%0A%09%09%09url%0A%09%09%09_id%0A%09%09%7D%0A%20%20%20%20image%7B%0A%09%09%09url%0A%09%09%09_id%0A%09%09%7D%20%20%0A%20%20%20%20assessments%7B%0A%20%20%20%20%20%20description%0A%20%20%20%20%20%20score%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20username%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20surname%0A%20%20%20%20%20%20%20%20picture_profile%7B%0A%20%20%20%20%20%20%20%20%20%20url%0A%20%20%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%20%20specialties%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20description%0A%20%20%20%20%7D%0A%20%20user%7B%0A%20%20%20%20gender%20%20%20%20%0A%20%20%7D%0A%7D%0A%7D";
            request(constantes("URL_API") + consulta, { method: "GET" })
            .then( datos => this.setState({
                    professional: datos.data.professional,
                    sumatorio: datos.data.professional.assessments.length>0?
                                datos.data.professional.assessments.map(this.amount).reduce(this.sum):
                                0
                }) )};
                request(constantes("URL_API") + "/newschedules/", { method: "GET" })
                .then( datos => this.setState({
                    professionalSchedules: datos.filter(a => a.professional._id===this.props.match.params.id)
                }));
                if(auth.getToken() !== null){
                    request(constantes("URL_API") + "/users/"+auth.getUserInfo()._id, { method: "GET" })
                    .then( datos => this.setState({
                    arrayFavoritos: datos.favorite_professionals
                    }) );
                }
                
                //
                this.setState({totalEventos: this.state.events.length})
                //
                fetch(constantes("URL_API")+"/moments", {
                    method: "GET",
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${this.state.jwt}`
                    }
                  })
                  .then(data => data.json())
                  .then(response => {
                    this.setState({
                      moments: response,
                      moment_selected: response[0],
                      form: {
                        ...this.state.form,
                        starthour: response[0] ? response[0].time_start : null,
                        endhour: response[0] ? response[0].time_end : null
                      }
                    })      
                  })    
                       
        
            }

    componentDidUpdate(prevProps, prevState){
        if (prevState.id !== this.props.match.params.id){

            let consulta = "/graphql?query=%7B%0A%20professional(id%3A%22"+this.props.match.params.id+"%22)%7B%0A%20%20%20%20_id%0A%20%20%09profesional_name%0A%20%20%20%20description%0A%20%20%09createdAt%0A%09%09biography%0A%09%20%20media%7B%0A%09%09%09url%0A%09%09%09_id%0A%09%09%7D%0A%20%20%20%20image%7B%0A%09%09%09url%0A%09%09%09_id%0A%09%09%7D%20%20%0A%20%20%20%20assessments%7B%0A%20%20%20%20%20%20description%0A%20%20%20%20%20%20score%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20username%0A%20%20%20%20%20%20%20%20name%0A%20%20%20%20%20%20%20%20surname%0A%20%20%20%20%20%20%20%20picture_profile%7B%0A%20%20%20%20%20%20%20%20%20%20url%0A%20%20%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%20%20specialties%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20description%0A%20%20%20%20%7D%0A%20%20user%7B%0A%20%20%20%20gender%20%20%20%20%0A%20%20%7D%0A%7D%0A%7D";
            
            request(constantes("URL_API") + consulta, { method: "GET" })
            .then( datos => this.setState({
              professional: datos.data.professional,
              id:this.props.match.params.id,
              
            }) )
            request(constantes("URL_API") + "/newschedules/", { method: "GET" })
                .then( datos => this.setState({
                    professionalSchedules: datos.filter(a => a.professional._id===this.props.match.params.id)
                }));
            window.scrollTo(0, 0)};
            
         
        }
        changeMoment = (e) => {
            const moment_id = e.target.value;
            const result = this.state.moments.find(moment => moment._id === moment_id);
            this.setState({
              moment_selected: result,
              form: {
                ...this.state.form,
                starthour: result.time_start,
                endhour: result.time_end
              }
            })
          }
        
          getStartHour = (e) => {
            this.setState({
              form: {
                ...this.state.form,
                starthour: e.target.value
              }
            })
          }
        
          getEndHour = (e) => {
            this.setState({
              form: {
                ...this.state.form,
                endhour: e.target.value
              }
            })
          }
        
          getStartMin = (e) => {
            this.setState({
              form: {
                ...this.state.form,
                startmin: e.target.value
              }
            })
          }
        
          getEndMin = (e) => {
            this.setState({
              form: {
                ...this.state.form,
                endmin: e.target.value
              }
            })
          }
        
          changeWeekday = (e) => {
            this.setState({
              form: {
                ...this.state.form,
                week_day: e.target.value
              }
            })
          }
        
          getMinDuration = (e) => {
            let posible_min_select;
            if(e.target.value==="45"){
              posible_min_select = ["00", "15", e.target.value];
            }else if(e.target.value==="60"){
              posible_min_select = ["00"];
            }else if(e.target.value==="30"){
              posible_min_select = ["00", e.target.value];
            }
            this.setState({
              form: {
                ...this.state.form,
                minduration: e.target.value,
                posible_min_select
              }
            })
          }
        
          checkSchedule = () => {
            let errormsg = "";
            const moment_time_selected = {
              time_start: this.state.moment_selected.time_start,
              time_end: this.state.moment_selected.time_end,
              name: this.state.moment_selected.moment
            }           
            const form_time = {
              time_start: this.state.form.starthour,
              time_end: this.state.form.endhour,
              minduration: this.state.form.minduration
            }
            
            if(form_time.time_start < moment_time_selected.time_start || form_time.time_start > moment_time_selected.time_end){
                errormsg = errormsg + "La hora de inicio no coincide con el tramo de "+moment_time_selected.name+". \n";
            }
            if(form_time.time_end < moment_time_selected.time_start || form_time.time_end > moment_time_selected.time_end){
              errormsg = errormsg + "La hora de fin no coincide con el tramo de "+moment_time_selected.name+". \n";
            } 
            errormsg.length > 0&&alert(errormsg);
            this.setState({
                time_selected: moment_time_selected,
                form_time: form_time,
                muestraCalendario: true})
          }
          
          submint = () => {
            const form = this.state.form;
            const moment_selected = this.state.moment_selected;
        
            let time_start = form.starthour+":";
            if(form.startmin<10){
              time_start = time_start+"0"+form.startmin;
            }else{
              time_start = time_start + form.startmin;
            }
        
            let time_end = form.endhour+":";
            if(form.endmin<10){
              time_end = time_end+"0"+form.endmin;
            }else{
              time_end = time_end + form.endmin;
            }
        
            console.log({
                  professional: this.state.id,
                  moment: moment_selected._id,
                  week_day: form.week_day,
                  time_start,
                  time_end,
                  appointment_time_min: form.minduration
                })
            this.checkSchedule();        
          }
    render() {
        const localizer = BigCalendar.momentLocalizer(moment);
        return(
            <div className="main-content professional-profile" key={this.props.match.params.id}>              
                <Row>
                    <Col>
                        <MainSearch />
                        <div className="media-data">
                            {this.state.professional.media!==null ?
                            <Player src={constantes("URL_API")+this.state.professional.media.url}>
                                <ControlBar autoHide={false} className="videocontrols" />
                            </Player>:
                            this.state.image!==null?<div className="imgProfile"><img src={constantes("URL_API")+this.state.professional.image.url} alt="psicólogo"/></div>:<div className="imgProfile"><img src="/img/userplaceholder.png" alt="Psicólogo"/></div>}
                        </div>
                    </Col>
                </Row>
                
                <Row className="psinfo">
                    <Col lg="2" />
                    <Col lg="5" className="datos">
                        {   
                            this.state.professional.user===null ?
                            <h2>{this.state.professional.profesional_name}</h2>:
                            <h2>
                                { this.state.professional.user.gender==="Hombre" ? "Dr. " : "Dra. " }{this.state.professional.profesional_name}
                            </h2>
                        }
                        <Rating profile data={this.state.professional.assessments} media={this.state.professional.assessments.length>0?this.state.sumatorio/this.state.professional.assessments.length:0}/>
                        <h3>{this.state.professional.description}</h3>
                        <div className="biography">{this.state.professional.biography ? renderHTML(this.state.professional.biography) : null}</div>
                        <Button>Biografía Completa ></Button>                        
                        <hr/>
                        <h3>Valoraciones</h3>
                        <Row>
                            <Col lg="6" className="rating-desglose">                                
                                <RatingParcial partial-rating name="Puntualidad" />
                            </Col>
                            <Col lg="6" className="rating-desglose">                                
                                <RatingParcial partial-rating name="Escucha activa" />
                            </Col>
                            <Col lg="6" className="rating-desglose">                                
                                <RatingParcial partial-rating name="Calidad de la sesión" />
                            </Col>
                            <Col lg="6" className="rating-desglose">                                
                                <RatingParcial partial-rating name="Satisfacción" />
                            </Col>
                            <Col lg="6" className="rating-desglose">     
                                <RatingParcial partial-rating name="Profesionalidad" />
                            </Col>
                        </Row>
                        <hr/>
                        <Col>
                            <CommentsClaim limit={2} proView id={this.state.id}/>
                        </Col>                        
                    </Col>
                    <Col className="actions">
                    
                        <Row>
                            <Col>
                                <Button className={auth.getToken()?"check":"chekea"} onClick={this.toggleHorarios}><FontAwesomeIcon icon={faCalendarAlt} /> Disponibilidad</Button>
                                {auth.getToken()?<Button className={this.state.arrayFavoritos?(this.state.arrayFavoritos.some(item=> item._id === this.state.id)===false?"save":"saved"):"save"} onClick={this.gestionaFav}>
                                            <FontAwesomeIcon icon={faHeart} /> 
                                            {this.state.arrayFavoritos?(this.state.arrayFavoritos.some(item=> item._id === this.state.id)===false?" Guardar":" Guardado"):" Guardar"}
                                    </Button>:null}
                            </Col>
                        </Row>
                        <Row>                            
                            <Col>{auth.getToken()?<Button className="cita" onClick={this.toggle}>Solicitar Cita {this.state.showAppointmentForm?"▲":"▼"}</Button>:""}</Col>
                        </Row>
                        <Modal isOpen={this.state.modalHorarios} toggle={this.toggleHorarios} className="modalCom horarios">              
                            <ModalBody>
                                <h3>Horarios de{ this.state.professional.user.gender==="Hombre" ? "l Dr. " : " la Dra. " }{this.state.professional.profesional_name}</h3>
                                {(this.state.professionalSchedules.length <=0?<p className="empty">No hay ningún horario disponible con {this.state.professional.profesional_name}</p>:null)}
                                { (this.state.professionalSchedules.some(e => e.weekday = "Lunes"))?
                                <p><b>Lunes: </b>
                                {this.state.professionalSchedules.filter(e => e.week_day==="Lunes").map((schedule)=>{
                                    console.log(this.state.professionalSchedules.filter(e => e.week_day==="Lunes"))
                                    return "De "+schedule.time_start+":00 a "+schedule.time_end+":00. "})}</p>
                                :null}
                                {(this.state.professionalSchedules.some(e => e.weekday = "Martes"))?
                                <p><b>Martes: </b>
                                {this.state.professionalSchedules.filter(e => e.week_day==="Martes").map((schedule)=>{
                                    console.log(this.state.professionalSchedules.filter(e => e.week_day==="Martes"))
                                    return "De "+schedule.time_start+":00 a "+schedule.time_end+":00. "})}</p>
                                :null}
                                {(this.state.professionalSchedules.some(e => e.weekday = "Miércoles"))?
                                <p><b>Miércoles: </b>
                                {this.state.professionalSchedules.filter(e => e.week_day==="Miércoles").map((schedule)=>{
                                    console.log(this.state.professionalSchedules.filter(e => e.week_day==="Miércoles"))
                                    return "De "+schedule.time_start+":00 a "+schedule.time_end+":00. "})}</p>
                                :null}
                                {(this.state.professionalSchedules.some(e => e.weekday = "Jueves"))?
                                <p><b>Jueves: </b>
                                {this.state.professionalSchedules.filter(e => e.week_day==="Jueves").map((schedule)=>{
                                    console.log(this.state.professionalSchedules.filter(e => e.week_day==="Jueves"))
                                    return "De "+schedule.time_start+":00 a "+schedule.time_end+":00. "})}</p>
                                :null}
                                {(this.state.professionalSchedules.some(e => e.weekday = "Viernes"))?
                                <p><b>Viernes: </b>
                                {this.state.professionalSchedules.filter(e => e.week_day==="Viernes").map((schedule)=>{
                                    console.log(this.state.professionalSchedules.filter(e => e.week_day==="Viernes"))
                                    return "De "+schedule.time_start+":00 a "+schedule.time_end+":00. "})}</p>
                                :null}
                                {(this.state.professionalSchedules.some(e => e.weekday = "Sábado"))?
                                <p><b>Sábado: </b>
                                {this.state.professionalSchedules.filter(e => e.week_day==="Sábado").map((schedule)=>{
                                    console.log(this.state.professionalSchedules.filter(e => e.week_day==="Sábado"))
                                    return "De "+schedule.time_start+":00 a "+schedule.time_end+":00. "})}</p>
                                :null}
                                {(this.state.professionalSchedules.some(e => e.weekday = "Domingo"))?
                                <p><b>Domingo: </b>
                                {this.state.professionalSchedules.filter(e => e.week_day==="Domingo").map((schedule)=>{
                                    console.log(this.state.professionalSchedules.filter(e => e.week_day==="Domingo"))
                                    return "De "+schedule.time_start+":00 a "+schedule.time_end+":00. "})}</p>
                                :null}
                                <Button color="primary" onClick={this.toggleHorarios}>Salir</Button>
                            </ModalBody>              
                        </Modal>
                        {
                                <Modal isOpen={this.state.showAppointmentForm} toggle={this.toggle} className="selectorHora">
                                    <ModalBody>
                                        <h3>Registra tu cita</h3>                                        
                                        <Row>
                                            {/* <Col className="preferenciasHora">
                                                    <Form inline>
                                                        <Label htmlFor="traamo">{"Tramo: "}</Label>
                                                        <Input type="select" onChange={this.changeMoment}>
                                                            {this.state.moments&&this.state.moments.map((moment,i) => {
                                                                return <option key={i} value={moment._id}> {moment.moment+" "+moment.time_start+" - "+moment.time_end} </option>
                                                            })}
                                                        </Input>
                                                    </Form>
                                                    <Form inline>
                                                        <Label htmlFor="horainicio">{"Hora inicio: "}</Label>
                                                        <Input type="number" placeholder="Hora inicio" min={this.state.moment_selected&&this.state.moment_selected.time_start} max={this.state.moment_selected&&this.state.moment_selected.time_end} defaultValue={this.state.moment_selected&&this.state.moment_selected.time_start} onChange={this.getStartHour}/>
                                                        <Input type="select">
                                                            {this.state.form.posible_min_select&&this.state.form.posible_min_select.map((min,i)=>{
                                                            return<option value={min} key={min}>{min}</option>
                                                            })}
                                                        </Input>
                                                    </Form>
                                                    <Form inline>
                                                        <Label htmlFor="horafin">{"Hora fin: "}</Label>
                                                        <Input type="number" placeholder="Hora fin" min={this.state.moment_selected&&this.state.moment_selected.time_start} max={this.state.moment_selected&&this.state.moment_selected.time_end} defaultValue={this.state.moment_selected&&this.state.moment_selected.time_end} onChange={this.getEndHour}/>
                                                        <Input type="select">
                                                            {this.state.form.posible_min_select&&this.state.form.posible_min_select.map((min,i)=>{
                                                            return<option value={min} key={min}>{min}</option>
                                                            })}
                                                        </Input>
                                                    </Form> 
                                                    <Form inline>
                                                        <Label htmlFor="diaSemana">Día de la semana:</Label>  
                                                        <Input type="select" onChange={this.changeWeekday}>
                                                            <option>Lunes</option>
                                                            <option>Martes</option>
                                                            <option>Miercoles</option>
                                                            <option>Jueves</option>
                                                            <option>Viernes</option>
                                                            <option>Sabado</option>
                                                            <option>Domingo</option>
                                                        </Input>                                                  
                                                    </Form>
                                                    <Form inline>
                                                        <Label htmlFor="duracion">Duración:</Label>  
                                                        <Input type="select" onChange={this.getMinDuration}>
                                                            <option value={30}>30'</option>
                                                            <option value={45}>45'</option>
                                                            <option value={60}>60'</option>
                                                        </Input>                                                  
                                                    </Form>

                                                    <Input type="button" value="Mostrar horario" onClick={this.submint}/> 

                                                 
                                            </Col> */}
                                            <Col>
                                            <BigCalendar
                                                localizer={localizer}
                                                events={this.state.events}
                                                defaultDate={new Date()}
                                                defaultView="week"
                                                style={{height:"50vh"}}
                                                popup={true}
                                                views={['week','day']}
                                                selectable
                                                step={15}
                                                onSelectSlot={this.handleSelect}
                                                onSelectEvent={this.deshazFecha}
                                                eventPropGetter={event => ({
                                                    style: {                                                        
                                                      backgroundColor: event.bgColor
                                                    },
                                                  })}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="notes">
                                                <label htmlFor="notes">Notas</label>
                                                <Input type="textarea" name="notes" id="notes" onChange={this.handleChangeNotes} placeholder="Escribe los datos relevantes para que el profesional los tenga presentes."/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button className="cita" onClick={this.cambiaModal}>Guardar cita</Button>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                        }
                        {
                            <Modal isOpen={this.state.modalMod} toggle={this.toggleMod} className="modalCom">              
                            <ModalBody>
                                <h3>Por favor, comprueba los datos de tu cita</h3>
                                <p>¿Estás seguro que deseas reservar una cita con {this.state.professional.profesional_name} para el dia{' '} 
                                    <Moment format="LL" className="fecha">{this.state.date}</Moment>
                                    <span> a las </span> 
                                    <Moment format="LT">{this.state.date}</Moment>
                                    ?</p>
                                <p>Si es así haz click en el botón confirmar y podrás acceder al pago de la cita</p>
                                <Button color="secondary" onClick={this.cambiaModal}>Revisar datos</Button>{' '}
                                <Button color="primary" onClick={this.guardarCita}>Confirmar ></Button>
                            </ModalBody>              
                            </Modal>
                        }
                    </Col>
                    <Col lg="2" />
                </Row>
                <Row>
                    <Col>
                        <FeaturedProfessional 
                            titulo="Otros profesionales" 
                            subtitle={"Si "+ (this.state.professional.user===null?null:(this.state.professional.user.gender==="Hombre"?"el Dr. ":"la Dra. "))+this.state.professional.profesional_name+ "  no te convence, puedes ver estos otros profesionales"} 
                            limit={4} id={this.state.id} 
                            specialties={this.state.professional.specialties}/>
                    </Col>                                        
                </Row>
                <div className="backg"></div>
               
                
            </div>
        );
    }
}

export default Professional;