import  { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import auth from './auth';

const reducer = (state, action) => {
    let returnState = {
        hasToken: auth.getToken()===null?false:true,
        assessments: [],
        professionals: [],
        specialties: [],
        modalAppointments: false
    };

    if(action.type === "loadAssessments"){
        returnState = {
            ...state,
            assessments: action.assessments
        }
    }else if(action.type === "loadProfessionals"){
        returnState = {
            ...state,
            professionals: action.professionals
        }
    }else if(action.type === "loadSpecialties"){
        returnState = {
            ...state,
            specialties: action.specialties
        }
    }else if(action.type === "loadAppointments"){
        returnState = {
            ...state,
            appointments: action.appointments
        }
    }else if (action.type === "toogleLogin") {
        returnState = {
            ...state,
            hasToken: auth.getToken()===null?false:true
        };
    }
    else if (action.type === "setIntervalId") {
        returnState = {
            ...state,
            intervalId: action.id
        };
    }else if (action.type === "toogleIsOpen") {
        returnState = {
            ...state,
            isOpen: !state.isOpen
        };
    }else if (action.type === "toogleModalAppointments") {
        returnState = {
            ...state,
            modalAppointments: !state.modalAppointments
        };
    }
    return returnState;
}

export default createStore(reducer, { hasToken: false }, applyMiddleware(thunk));